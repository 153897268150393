import React from 'react';

import { IntlProvider } from '../internationalisation/IntlProvider';
import Topbar from './Topbar';

const Layout = ({ children, backUrl }) => {
    return (
        <IntlProvider>
            <div id="page-content">
                <Topbar />
                <div id="content-body">{children}</div>
            </div>
        </IntlProvider>
    );
};

export default Layout;
