import { API } from '../../common/constants/api';
import React, { useEffect, useState } from 'react';

import { HTTP_HEADER } from '../../common/constants/misc';
import { STORAGE } from '../../common/constants/keys';
import { deleteFromLocalStorage, isChina, setLocalStorage } from '../../common/constants/helper';

const ImageProvider = (props) => {
    const LocalImage = React.lazy(() => import('./LocalImage'));
    const CdnImage = React.lazy(() => import('./CdnImage'));
    const [china, setIsChina] = useState();

    const checkIsChina = () => {
        try {
            var req = new XMLHttpRequest();
            req.open('GET', API.HOST, false);
            req.send(null);
            if (req.getResponseHeader(HTTP_HEADER.CHINA_IDENTIFIER.toLowerCase())) {
                setLocalStorage(STORAGE.ISCHINA, true);
                setIsChina(true);
            } else {
                deleteFromLocalStorage(STORAGE.ISCHINA);
                setIsChina(false);
            }
        } catch (err) {
            setIsChina(isChina());
        }
    };

    useEffect(() => {
        checkIsChina();
    }, []);

    return (
        <>
            <React.Suspense fallback={<></>}>
                {china ? <CdnImage /> : <LocalImage />}
            </React.Suspense>
            {props.children}
        </>
    );
};

export default ImageProvider;
