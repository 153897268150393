import React from 'react';

import { CDN_ICONS } from '../../common/constants/cdnIcons';
import { LOCAL_ICONS } from '../../common/constants/localIcons';
import { isChina } from '../../common/constants/helper';
import ErrorLayout from './ErrorLayout';

const SessionExpired = () => {
    const ICONS = isChina() ? CDN_ICONS : LOCAL_ICONS;

    return (
        <ErrorLayout
            icon={ICONS.WARNING}
            flickerText="ERROR"
            text1="Disconnected"
            text2="Game Disconnected Please Try Again Later"
            className="error"
        />
    );
};

export default SessionExpired;
