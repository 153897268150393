import React from 'react';

import { CDN_ICONS } from '../../common/constants/cdnIcons';
import { LOCAL_ICONS } from '../../common/constants/localIcons';
import { isChina } from '../../common/constants/helper';
import ErrorLayout from './ErrorLayout';

const Restriction = () => {
    const ICONS = isChina() ? CDN_ICONS : LOCAL_ICONS;
    return (
        <ErrorLayout
            icon={ICONS.WARNING}
            flickerText="ERROR"
            text2="You are restricted to access."
            className="error"
        />
    );
};

export default Restriction;
