import { API } from '../common/constants/api';
import { ROUTES } from '../common/constants/routing';
import { STORAGE } from '../common/constants/keys';
import { getLocalStorage } from '../common/constants/helper';
import useApi from './hooks/useApi';

const { HOST, APIBASE, CLIENT } = API;

export function useVerifyThirdParty(payload, refererUrl, pathname) {
    let oldSessionId = getLocalStorage(STORAGE.USER);
    if (oldSessionId === undefined) {
        oldSessionId = '';
    }
    let oldCurrency = undefined;
    if (oldCurrency === undefined) {
        oldCurrency = '';
    }

    let platform = payload.platform;
    if (platform === undefined) {
        platform = '';
    }

    let URL = undefined;
    if (pathname === ROUTES.CLIENT.AUTH) {
        URL = `${HOST}${APIBASE}${CLIENT.VERIFY_THIRD_PARTY}?payload=${encodeURIComponent(
            payload.payload,
        )}&signature=${encodeURIComponent(payload.signature)}&refererUrl=${encodeURIComponent(
            refererUrl,
        )}&oSession=${encodeURIComponent(oldSessionId)}&oCurrency=${encodeURIComponent(
            oldCurrency,
        )}&platform=${encodeURIComponent(platform)}`;
    } else if (pathname === ROUTES.CLIENT.SABA_AUTH) {
        URL = `${HOST}${APIBASE}${CLIENT.VERIFY_SABA}?funPlay=${encodeURIComponent(
            payload.FunPlay ?? false,
        )}&gameCode=${encodeURIComponent(payload.GameCode)}&homeUrl=${encodeURIComponent(
            payload.HomeUrl ?? '',
        )}&langCode=${encodeURIComponent(payload.LangCode ?? '')}&token=${encodeURIComponent(
            payload.Token,
        )}&refererUrl=${encodeURIComponent(refererUrl)}&oSession=${encodeURIComponent(
            oldSessionId,
        )}&oCurrency=${encodeURIComponent(oldCurrency)}`;
    }

    const { loading, error, response } = useApi({
        method: 'GET',
        trigger: Object.keys(payload).length !== 0,
        url: URL,
    });

    return { error, loading, response };
}

export function useGetGameRedirect(payload) {
    const { loading, error, response } = useApi({
        method: 'GET',
        trigger: Object.keys(payload).length !== 0,
        options: {
            params: {
                gameCode: payload.gameCode,
                token: payload.token,
            },
        },
        url: `${HOST}${APIBASE}${CLIENT.GET_REDIRECT}`,
    });

    return { error, loading, response };
}
