import React, { useEffect } from 'react';

import * as QueryString from 'query-string';
import { useHistory } from 'react-router-dom';

import { GAME_REDIRECTION } from '../../common/constants/misc';
import { ROUTES } from '../../common/constants/routing';
import { STORAGE } from '../../common/constants/keys';
import { setLocalStorage } from '../../common/constants/helper';
import { useGetGameRedirect } from '../../services/userService';

const GetGameResult = () => {
    const history = useHistory();
    const params = QueryString.parse(location.search);

    const { error, response } = useGetGameRedirect({
        gameCode: params.GameCode,
        token: params.Token,
    });

    useEffect(() => {
        if (response) {
            const hostOrigin = location.origin.includes('localhost')
                ? GAME_REDIRECTION + '/' + response.data.redirect
                : location.origin + '/' + response.data.redirect;
            window.location.href = hostOrigin + '/GetGameResult' + location.search;
        }

        if (error) {
            setLocalStorage(STORAGE.LANGUAGE, params.LangCode);
            history.push(ROUTES.ERROR);
        }
    }, [response, error, params.LangCode, history]);

    return <div>GetGameResult</div>;
};

export default GetGameResult;
