import React from 'react';

export const Image = ({ src, alt = '', onClick, styles, ...rest }) => {
    return (
        <img
            style={styles}
            src={src.hasOwnProperty('default') ? src.default : src}
            alt={alt}
            {...rest}
            onClick={onClick}
        />
    );
};
