import { LANGUAGES } from './localization';
import _ from 'lodash';
import intl from 'react-intl-universal';

export const getSupportedLocales = () => ({
    'zh-CN': require('../../components/locales/zh-CN.json'),
    'en-US': require('../../components/locales/en-US.json'),
    'vi-VN': require('../../components/locales/vi-VN.json'),
    'id-ID': require('../../components/locales/id-ID.json'),
    'th-TH': require('../../components/locales/th-TH.json'),
    'ko-KR': require('../../components/locales/ko-KR.json'),
    'hi-IN': require('../../components/locales/hi-IN.json'),
});

export const getTranslation = (sentence, params = {}) => {
    if (sentence) return intl.get(sentence, params).defaultMessage(sentence);
};

export const getBrowserLocale = () => {
    if (navigator.languages) return navigator.languages[0];
    return navigator.language || navigator.userLanguage;
};

export const getLanguageByCode = (value) => {
    const foundLanguage = _.find(LANGUAGES, (l) => l.value === value);
    return foundLanguage;
};
