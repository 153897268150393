import { useHistory } from 'react-router';
import React from 'react';

import { CDN_ICONS } from '../../common/constants/cdnIcons';
import { Image } from '../../common/components/Image';
import { LOCAL_ICONS } from '../../common/constants/localIcons';
import { isChina, isLandscape } from '../../common/constants/helper';

const Topbar = () => {
    const history = useHistory();
    const ICONS = isChina() ? CDN_ICONS : LOCAL_ICONS;

    var url = null;
    var isSaba = false;

    if (history.location.state) {
        url = history.location.state.url;
        isSaba = history.location.state.isSaba;
    }

    return (
        !(isSaba && isLandscape()) && (
            <div id="top-bar">
                <div className="title">
                    {url && (
                        <div className="back" onClick={() => (window.location = url)}>
                            <Image src={ICONS.BACK_ICON} />
                        </div>
                    )}

                    <div className="title-container">
                        <h1>AdvantPlay</h1>
                    </div>
                </div>
            </div>
        )
    );
};

export default Topbar;
