import React, { useEffect } from 'react';

import * as QueryString from 'query-string';
import { useHistory } from 'react-router-dom';

import { GAME_REDIRECTION, HTTP_HEADER } from '../../common/constants/misc';
import { ROUTES } from '../../common/constants/routing';
import { STORAGE } from '../../common/constants/keys';
import { deleteFromLocalStorage, setLocalStorage } from '../../common/constants/helper';
import { useVerifyThirdParty } from '../../services/userService';

const VerifyThirdParty = () => {
    const history = useHistory();
    const pathname = location.pathname;
    const params = QueryString.parse(location.search);

    let refererUrl = document.referrer;
    if (refererUrl === '' || refererUrl === undefined) {
        refererUrl = window.location.origin;
    }

    const redirect = (errorCode, url) => {
        switch (errorCode) {
            case 401:
                history.push(ROUTES.SESSION_EXPIRED, {
                    url,
                    isSaba: pathname === ROUTES.CLIENT.SABA_AUTH,
                });
                break;
            case 423:
                history.push(ROUTES.RESTRICTION, {
                    url,
                    isSaba: pathname === ROUTES.CLIENT.SABA_AUTH,
                });
                break;
            case 503:
                history.push(ROUTES.MAINTENANCE, {
                    url,
                    isSaba: pathname === ROUTES.CLIENT.SABA_AUTH,
                });
                break;
            default:
                history.push(ROUTES.ERROR, { url, isSaba: pathname === ROUTES.CLIENT.SABA_AUTH });
                break;
        }
    };

    const { error, response } = useVerifyThirdParty(params, refererUrl, pathname);

    useEffect(() => {
        if (response) {
            setLocalStorage(STORAGE.LANGUAGE, response.data.language);

            if (response.data.redirect && response.data.params) {
                deleteFromLocalStorage(STORAGE.LANGUAGE);
                const hostOrigin = location.origin.includes('localhost')
                    ? GAME_REDIRECTION + '/' + response.data.redirect
                    : location.origin + '/' + response.data.redirect;

                window.location.href = hostOrigin + response.data.params;
            } else if (response.data.isMaintenance) redirect(503, response.backUrl);
            else if (response.data.isBan || response.data.isDisconnect) {
                redirect(423, response.backUrl);
            }
        }

        if (error) {
            if (error.headers === undefined) {
                redirect();
            }

            if (
                error.headers[HTTP_HEADER.CHINA_IDENTIFIER] ||
                error.headers[HTTP_HEADER.CHINA_IDENTIFIER.toLocaleLowerCase()]
            ) {
                setLocalStorage(STORAGE.ISCHINA, true);
            } else {
                deleteFromLocalStorage(STORAGE.ISCHINA);
            }

            setLocalStorage(STORAGE.LANGUAGE, error.data.language);
            redirect(error.status, error.data.backUrl);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, history, response]);

    return <div></div>;
};

export default VerifyThirdParty;
