import React, { Fragment, useEffect, useState } from 'react';

import intl from 'react-intl-universal';

import { STORAGE } from '../../common/constants/keys';
import {
    getBrowserLocale,
    getLanguageByCode,
    getSupportedLocales,
} from '../../common/constants/locale';
import { getLocalStorage } from '../../common/constants/helper';

export const IntlProvider = (props) => {
    const [initDone, setInitDone] = useState(false);

    // ============================================
    // HOOKS
    // ============================================

    useEffect(() => {
        const currentlanguage =
            getLocalStorage(STORAGE.LANGUAGE) === undefined
                ? 'en-US'
                : getLocalStorage(STORAGE.LANGUAGE).replace(/['"]+/g, '');
        const countryFound = getLanguageByCode(currentlanguage);
        const browserLocale = getBrowserLocale().indexOf('zh') !== -1 ? 'zh-CN' : 'en-US';

        intl.init({
            currentLocale: countryFound ? currentlanguage : browserLocale,
            locales: getSupportedLocales(),
        }).then(() => {
            setInitDone(true);
        });
    }, []);

    // ============================================
    // RENDER
    // ============================================

    return initDone && <Fragment>{props.children}</Fragment>;
};
