import { STORAGE } from './keys';

export const getLocalStorage = (key) => {
    try {
        const value = localStorage.getItem(key);
        if (value === null) return undefined;
        return value;
    } catch (err) {
        console.log(err);
        return undefined;
    }
};

export const setLocalStorage = (key, value) => {
    try {
        localStorage.setItem(key, value);
    } catch (err) {
        console.log(err);
    }
};

export const deleteFromLocalStorage = (key) => {
    try {
        localStorage.removeItem(key);
    } catch (err) {
        console.log(err);
    }
};

export const isChina = () => getLocalStorage(STORAGE.ISCHINA);

export const loadCss = (url, id) => {
    var link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.href = url;
    link.id = id;
    document.body.appendChild(link);
};

export const detectMobile = () => {
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i,
    ];

    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
};

export const isLandscape = () => {
    return window.self !== window.top && detectMobile() && window.innerWidth > window.innerHeight;
};
