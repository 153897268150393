export const ROUTES = {
    ERROR: '/error',
    MAINTENANCE: '/maintenance',
    RESTRICTION: '/restriction',
    SESSION_EXPIRED: '/expired',
    LANDING: '/',
    CLIENT: {
        AUTH: '/client/auth',
        SABA_AUTH: '/LaunchGame',
        RESULT: '/GetGameResult',
    },
};
