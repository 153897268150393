/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useReducer, useState } from 'react';

import * as defaultAxios from 'axios';

import { ACTIONS, initialResponse, responseReducer } from './apiReducer';
//import { getCurrentUser, isUserLoggedIn } from '../../common/helpers/localStorage';
//import { refreshTokenInterceptor } from './interceptors';

/**
 * Params
 * @param  {AxiosInstance} [axios] - (optional) The custom axios instance
 * @param  {String} url - The request URL
 * @param  {('GET'|'POST'|'PUT'|'DELETE'|'HEAD'|'OPTIONS'|'PATCH')} method - The request method
 * @param  {Object} [options] - (optional) The config options of Axios.js (https://goo.gl/UPLqaK)
 * @param  {Object|String} trigger - (optional) The conditions for AUTO RUN, refer the concepts of [conditions](https://reactjs.org/docs/hooks-reference.html#conditionally-firing-an-effect) of useEffect, but ONLY support string and plain object. If the value is a constant, it'll trigger ONLY once at the begining
 * @param  {Function} [forceDispatchEffect] - (optional) Trigger filter function, only AUTO RUN when get `true`, leave it unset unless you don't want AUTO RUN by all updates of trigger
 * @param  {Function} [customHandler] - (optional) Custom handler callback, NOTE: `error` and `response` will be set to `null` before request
 */

/**
 * Returns
 * @param  {Object} response - The response of Axios.js (https://goo.gl/dJ6QcV)
 * @param  {Object} error - HTTP error
 * @param  {Boolean} loading - The loading status
 * @param  {Function} executeRequest - MANUAL RUN trigger function for making a request manually
 */

const { CancelToken } = defaultAxios;

const useAPi = ({
    axios = defaultAxios,
    url,
    method = 'GET',
    options = {},
    trigger,
    forceDispatchEffect,
    customHandler,
    contentType,
} = {}) => {
    const [results, dispatch] = useReducer(responseReducer, initialResponse);
    const [innerTrigger, setInnerTrigger] = useState(0);

    const outerTrigger = trigger;
    const dispatchEffect = forceDispatchEffect || (() => true);

    const composeHeaders = () => {
        let language = 'en-US';
        const headers = {
            'Accept-Language': language || 'en-US',
            'Content-Type': contentType ? contentType : 'application/json',
        };
        // if (isUserLoggedIn())
        //     return {
        //         ...headers,
        //         Authorization: `Bearer ${getCurrentUser().token}`,
        //     };
        // else return headers;
        return headers;
    };

    // axios.default.interceptors.request.use(refreshTokenInterceptor);

    useEffect(() => {
        const handler = (error, response) => {
            if (customHandler) {
                customHandler(error, response);
            }
        };

        if (!url || !dispatchEffect()) return;

        if (typeof outerTrigger === 'undefined' && !innerTrigger) return;

        handler(null, null);
        dispatch({
            type: ACTIONS.INIT,
        });

        const source = CancelToken.source();

        axios({
            cancelToken: source.token,
            method,
            ...{
                ...options,
                headers: composeHeaders(),
            },
            url,
        })
            .then((response) => {
                handler(null, response);
                dispatch({
                    payload: response,
                    type: ACTIONS.SUCCESS,
                });
            })
            .catch((error) => {
                if (error.response) {
                    handler(error, null);
                    if (!defaultAxios.isCancel(error)) {
                        dispatch({
                            payload: error.response,
                            type: ACTIONS.FAILURE,
                        });
                    }
                } else {
                    handler(error, null);
                    dispatch({
                        payload: true,
                        type: ACTIONS.FAILURE,
                    });
                }
            });
        return () => {
            source.cancel();
        };
    }, [innerTrigger, outerTrigger]);

    return {
        ...results,
        executeRequest: () => {
            setInnerTrigger(+new Date());
        },
    };
};

export const axios = defaultAxios;

export default useAPi;
