import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import './App.css';
import { ROUTES } from './common/constants/routing';
import Error from './components/error/Error';
import GetGameResult from './components/result/GetGameResult';
import ImageProvider from './components/image/ImageProvider';
import LayoutRoute from './components/layout/LayoutRoute';
import Maintenance from './components/error/Maintenance';
import Restriction from './components/error/Restriction';
import SessionExpired from './components/error/SessionExpired';
import VerifyThirdParty from './components/verify/VerifyThirdParty';

function App() {
    return (
        <Router>
            <ImageProvider>
                <Switch>
                    <Route exact path={ROUTES.CLIENT.AUTH} component={VerifyThirdParty} />
                    <Route exact path={ROUTES.CLIENT.SABA_AUTH} component={VerifyThirdParty} />
                    <Route exact path={ROUTES.CLIENT.RESULT} component={GetGameResult} />
                    <LayoutRoute path={ROUTES.ERROR} component={Error} />
                    <LayoutRoute path={ROUTES.MAINTENANCE} component={Maintenance} />
                    <LayoutRoute path={ROUTES.RESTRICTION} component={Restriction} />
                    <LayoutRoute path={ROUTES.SESSION_EXPIRED} component={SessionExpired} />
                </Switch>
            </ImageProvider>
        </Router>
    );
}

export default App;
