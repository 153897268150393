import React from 'react';

import { CDN_ICONS } from '../../common/constants/cdnIcons';
import { LOCAL_ICONS } from '../../common/constants/localIcons';
import { isChina } from '../../common/constants/helper';
import ErrorLayout from './ErrorLayout';

const Maintenance = () => {
    const ICONS = isChina() ? CDN_ICONS : LOCAL_ICONS;

    return (
        <ErrorLayout
            icon={ICONS.TOOLS}
            text1="Sorry... We're down for"
            text2="Maintenance"
            className="maintenance"
        />
    );
};

export default Maintenance;
