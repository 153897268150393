export const API = {
    HOST:
        process.env.NODE_ENV === 'development' ? 'http://localhost:14000' : window.location.origin,
    CLIENT: {
        VERIFY_THIRD_PARTY: '/client/v2/verifyThirdParty',
        VERIFY_SABA: '/saba/v2/verifyThirdParty',
        GET_REDIRECT: '/saba/getGameRedirect',
    },
    APIBASE: '/api',
};
