import { useHistory } from 'react-router-dom';
import React from 'react';

import { Image } from '../../common/components/Image';
import { getTranslation } from '../../common/constants/locale';
import { isLandscape } from '../../common/constants/helper';

const ErrorLayout = ({ icon, flickerText, text1, text2, className }) => {
    const history = useHistory();

    var isSaba = false;

    if (history.location.state) {
        isSaba = history.location.state.isSaba;
    }

    if (isSaba && isLandscape()) {
        require('../../common/assets/css/landscape.css');
    }

    return (
        <div className="common-bg-box">
            <div className="preloader-box">
                <div className="preloader-box-loading-box">
                    <div className={`error-pic-box-2 ${className}`}>
                        <Image className="animate-flicker icon" src={icon} alt="warning" />
                        <br />
                        {flickerText && (
                            <span className="animate-flicker icon-text">
                                {getTranslation(flickerText)}
                            </span>
                        )}
                    </div>
                    <div>
                        <span className="error-text">{getTranslation(text1)}</span>
                    </div>
                    <div className={`error-main-box`}>
                        <span>{getTranslation(text2)}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ErrorLayout;
